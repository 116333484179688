import {createMuiTheme} from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

const opacities = [...Array(101).keys()].map(n => n / 100);

const defaultTheme = createMuiTheme();


/**
 * The theme of the application.
 * It should contain all semantic colors and typography definitions for the
 * app.
 * @type {Theme} The theme template.
 */
const lightThemeOptions = {

  // Colors
  palette: {
    type: 'light',
    primary: {main: "#009899"},
    secondary: {main: '#E8DBD2'},
    error: red,
  },

  // Typography
  typography: {
    useNextVariants: true,
    fontFamily: '"Work Sans", sans-serif',
  },

  // Custom
  shades: {
    darker: opacities.map(o => `rgba(0,0,0,${o})`),
    lighter: opacities.map(o => `rgba(255,255,255,${o})`),
  },

  animations: {
    beat: "beat ease infinite",
    floatY: "floatY ease-in-out infinite",
    spinCircle: "spin linear infinite",
    hueRotate: "hue-rotate linear infinite",
    grayscale: "grayscale ease alternate infinite",
    pulseWhite: "pulse-white ease infinite",
    pulsePrimary: "pulse-primary ease infinite",
    pulseSecondary: "pulse-secondary ease infinite",

  },

  overrides: {
    MuiButton: {
      // Name of the rule
      contained: {
        // Some CSS
        boxShadow: 'none',
        border: `solid 1px ${defaultTheme.palette.divider}`,
      },
    },
  },

};


export const lightTheme = responsiveFontSizes(createMuiTheme(lightThemeOptions));

const themes = {"light": lightTheme};
export default themes;