import React from "react";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import * as PropTypes from 'prop-types';
import logo from '../../shared/images/logo-white.png';
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {flow, size} from "lodash";
import {fade, Typography, withStyles} from "@material-ui/core";
import useUpdateLanguage from "../../shared/util/hooks/useUpdateLanguage";
import locales from "../../shared/localization/locales";
import useLocale from "../../shared/util/hooks/useLocale";
import {Translate} from "react-localize-redux";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import classnames from "classnames";
import Divider from "@material-ui/core/Divider";
import useScrollTo from "../../shared/util/hooks/useScrollTo";

const styles = theme => ({
  root: {
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
    color: theme.palette.primary.contrastText,
  },
  toolbar: {
    flexGrow: 1,
    margin: "auto",
    padding: theme.spacing(2),
    transition: "width 0.5s ease, padding 0.3s ease",
    [theme.breakpoints.up('xs')]: {width: '100%'},
    [theme.breakpoints.up('sm')]: {width: '98%'},
    [theme.breakpoints.up('md')]: {width: '92%'},
    [theme.breakpoints.up('lg')]: {width: '80%'},
  },
  stackedToolbar: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  stackedAppbar: {
    backgroundColor: fade(theme.palette.primary.main, 0.8),
  },
  logo: {
    width: "60px",
    verticalAlign: "middle",
    marginRight: theme.spacing(1),
  },
  logoText: {
    position: "relative",
    color: theme.palette.common.white,
    marginLeft: theme.spacing(1),
    top: 4,
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    cursor: "pointer",
    whiteSpace: "nowrap",
    marginRight: theme.spacing(2),
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.secondary.main,
    }
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  divider: {
    backgroundColor: theme.palette.common.white,
    opacity: 0.6,
    marginRight: theme.spacing(1),
  }
});

const appBar = withStyles(styles)((
  {
    mobileMoreAnchorEl, onOpenMobileMenu, onCloseMobileMenu,
    onOpenLanguageMenu, classes, languageMoreAnchorEl,
    onCloseLanguageMenu
  }
) => {
  const updateLanguage = useUpdateLanguage();
  const locale = useLocale();
  const scrollTrigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 20,
  });
  const scrollTo = useScrollTo();


  const linksItems = [
    {label: "AppBar.home", href: "#home"},
    {label: "AppBar.intro", href: "#intro"},
    {label: "AppBar.mission", href: "#mission"},
    {label: "AppBar.bobcaat", href: "#bobcaat"},
    {label: "AppBar.team", href: "#team"},
  ];

  const renderMobileMenu = () => (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      transformOrigin={{vertical: 'top', horizontal: 'right'}}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={onCloseMobileMenu}
      PaperProps={{style: {width: 170}}}
    >
      {linksItems
        .map(({label, href}, index) => (
          <MenuItem divider={index === size(linksItems) - 1}
                    onClick={flow(onCloseMobileMenu, () => scrollTo(href))}
                    key={label}>
            <Translate id={label}/>
          </MenuItem>
        ))
      }
      {locales.map(({slug, name}, index) => (
        <MenuItem onClick={flow(onCloseMobileMenu, () => updateLanguage(slug))}
                  key={slug}>
          {name}
        </MenuItem>
      ))}
    </Menu>
  );


  return (
    <AppBar elevation={scrollTrigger ? 4 : 0}
            className={classnames(classes.root, scrollTrigger && classes.stackedAppbar)}>
      <Toolbar
        className={classnames(classes.toolbar, scrollTrigger && classes.stackedToolbar)}>
        <div className={classes.link}
             onClick={() => window.location.href = '/'}>
          <img src={logo} className={classes.logo} alt="Logo"/>
          <Typography display="inline" className={classes.logoText}
                      variant="h5">Yellowstones</Typography>
        </div>
        <div className={classes.grow}/>
        <>
          <div className={classes.sectionDesktop}>
            {linksItems
              .map(({label, href}) => (
                <Button onClick={() => scrollTo(href)} color="inherit"
                        key={label} className={classes.link}>
                  <Translate id={label}/>
                </Button>
              ))}
            <Divider orientation='vertical' flexItem
                     className={classes.divider}/>
            <Button className={classes.link} color="inherit"
                    onClick={onOpenLanguageMenu}>
              {locale.name}
            </Button>
            <Menu
              anchorEl={languageMoreAnchorEl}
              anchorOrigin={{vertical: 'top', horizontal: 'right'}}
              transformOrigin={{vertical: 'top', horizontal: 'right'}}
              open={Boolean(languageMoreAnchorEl)}
              onClose={onCloseLanguageMenu}>
              {locales.map(({slug, name}) => (
                <MenuItem onClick={flow(onCloseLanguageMenu, () => updateLanguage(slug))}
                          key={slug}>
                  {name}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <div className={classes.sectionMobile}>
            <Button variant="outlined" color="inherit"
                    className={classes.button} onClick={onOpenMobileMenu}>
              <Translate id="AppBar.menu"/>
            </Button>
          </div>
          {renderMobileMenu(mobileMoreAnchorEl, onCloseMobileMenu)}
        </>
      </Toolbar>
    </AppBar>
  );
});

appBar.propTypes = {
  mobileMoreAnchorEl: PropTypes.any,
  onOpenMobileMenu: PropTypes.func.isRequired,
  onCloseMobileMenu: PropTypes.func.isRequired,
  onOpenLanguageMenu: PropTypes.func.isRequired,
  onCloseLanguageMenu: PropTypes.func.isRequired,
  languageMoreAnchorEl: PropTypes.any,
};


export default appBar;
