import { LocalizeContext } from 'react-localize-redux';
import {useContext} from "react";
import {getLocale} from "../../localization/locales";

const useUpdateLanguage = () => {
  const {setActiveLanguage, activeLanguage} =  useContext(LocalizeContext);

  return languageCode => {
    // If no language provided, reload from localStorage.
    if (!languageCode) {
      languageCode = localStorage.getItem("locale")
    }

    // If still nothing, abort.
    if (!languageCode) {
      return false;
    }

    // If lnaguage provided, update all parties only if necessary.
    if (!activeLanguage || (languageCode !== activeLanguage.code)) {
      // Ensure we manage required locale.
      if (getLocale(languageCode)) {
        setActiveLanguage(languageCode);
        localStorage.setItem("locale", languageCode);
        return true;
      }
    }

    return false;
  };
};

export default useUpdateLanguage;