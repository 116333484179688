import React from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import mission1 from "../../shared/images/mission-1.jpg";
import mission2 from "../../shared/images/mission-2.jpg";
import mission3 from "../../shared/images/mission-3.jpg";
import classnames from 'classnames';
import logo from "../../shared/images/logo.png";
import grey from "@material-ui/core/colors/grey";
import {Translate} from "react-localize-redux";
import useIsMobile from "../shared/mui/useIsMobile";

const styles = theme => ({
  root: {
    backgroundColor: grey[200],
  },
  container: {
    "& img": {
      maxWidth: "100%",
      backgroundSize: "cover",
    }
  },
  typography: {
    maxWidth: 480,
    minHeight: 300,
  },
  flex: {
    display: "flex",
  },
  flexReverse: {
    flexDirection: "row-reverse",
  },
  textCell: {
    display: "flex",
    padding: theme.spacing(8),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(6),
    }
  },
  image: {
    width: "100%",
    height: "100%",
    minHeight: 400,
  },
  jumbo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(8),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4),
    }
  },
  jumboText: {
    maxWidth: 1000,
    // color: theme.palette.primary.contrastText,
    // boxShadow: theme.shadows[12],
    borderRadius: 4,
    padding: theme.spacing(6),
    paddingTop: 0,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    }
  },
  logo: {
    width: 100,
    height: 100,
    display: "block",
  }
});


const Item = ({image, short, long, index, classes}) => {
  const isMobile = useIsMobile();

  const imageCell = (
    <Grid item xs={12} sm={6} className={classes.flex}>
      <div className={classes.image} style={{background: `url(${image}) center center no-repeat`, backgroundSize: "cover"}}/>
    </Grid>
  );
  const textCell = (
    <Grid item xs={12} sm={6}
          className={classnames(classes.textCell, (index % 2 === 1) && classes.flexReverse)}>
      <div className={classes.typography}>
        <Typography paragraph variant="h4"><Translate id={short}/></Typography>
        <Typography paragraph variant="body1"><Translate id={long}/></Typography>
      </div>
    </Grid>
  );
  return (index % 2 === 1 || isMobile) ? <>{textCell}{imageCell}</> : <>{imageCell}{textCell}</>
};

const ITEMS = [
  {
    image: mission1,
    short: "Mission.items._1.short",
    long: "Mission.items._1.long",
  },
  {
    image: mission2,
    short: "Mission.items._2.short",
    long: "Mission.items._2.long",
  },
  {
    image: mission3,
    short: "Mission.items._3.short",
    long: "Mission.items._3.long",
  },
];

const mission = ({classes}) => (
  <section className={classes.root} id="mission">
    <div className={classes.container}>
      <Grid container spacing={0}>
        {ITEMS.map((props, index) => (
          <Item {...props} key={index} index={index} classes={classes}/>
        ))}
      </Grid>
      <div className={classes.jumbo}>
        <img src={logo} alt={"logo"} className={classes.logo}/>
        <Typography variant="h4" align="center" className={classes.jumboText}>
          <Translate id="Mission.banner" options={{renderInnerHtml: true}}/>
        </Typography>
      </div>
    </div>
  </section>
);

export default withStyles(styles)(mission);