import React from 'react';
import {withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import usImage from "../../shared/images/us.jpg";
import Avatar from "@material-ui/core/Avatar";
import EqualityIcon from '../../shared/images/equality.svg';
import CollaborationIcon from '../../shared/images/collaboration.svg';
import InnovationIcon from '../../shared/images/innovation.svg';
import {Translate} from "react-localize-redux";

const styles = theme => ({
  root: {
    padding: "2rem",
  },
  container: {
    maxWidth: 1200,
    margin: "auto",
    "& img": {
      maxWidth: "100%",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.15)",
      }
    }
  },
  imageContainer: {
    display: "flex",
    overflow: "hidden",
    margin: theme.spacing(3),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    boxShadow: theme.shadows[24],
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  text: {
    paddingRight: theme.spacing(3),
  },
  quote: {},
  bold: {
    fontWeight: "bold",
  },
  values: {
    marginTop: theme.spacing(6),
  },
  value: {
    maxWidth: 350,
    display: "block",
    margin: "auto",
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: theme.spacing(2),
    },
  },
  valueIcon: {
    backgroundColor: "transparent",
    width: 80,
    height: 80,
    marginBottom: theme.spacing(3),
    "& svg":{
      width: "60%",
      height: "60%",
    }
  }
});


const Value = ({Icon, title, description, classes}) => (
  <Grid item sm={6} md={4}>
    <article className={classes.value}>
      <Avatar variant="rounded" className={classes.valueIcon}><img src={Icon} alt={<Translate id={title}/>}/></Avatar>
      <Typography variant="h6" gutterBottom className={classes.bold}><Translate id={title}/></Typography>
      <Typography variant="body1"
                  color="textSecondary"><Translate id={description}/></Typography>
    </article>
  </Grid>
);


const VALUES = [
  {
    Icon: EqualityIcon,
    title: "Intro.values._1.title",
    description: "Intro.values._1.description"
  },
  {
    Icon: CollaborationIcon,

    title: "Intro.values._2.title",
    description: "Intro.values._2.description"
  },
  {
    Icon: InnovationIcon,

    title: "Intro.values._3.title",
    description: "Intro.values._3.description"
  },
];

const intro = ({classes}) => (
  <section className={classes.root} id="intro">
    <div className={classes.container}>
      <Grid container spacing={4}>
        <Grid item md={5}>
          <div className={classes.text}>
            <Typography variant="overline" color="primary">
              <Translate id="Intro.title"/>
            </Typography>
            <Typography variant="h5" paragraph className={classes.bold}>
              <Translate id="Intro.subtitle"/>
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph
                        className={classes.quote}>
              <Translate id="Intro.message" options={{renderInnerHtml: true}}/>
            </Typography>
            <Typography variant="h6" className={classes.bold}>
              <Translate id="Intro.signature"/>
            </Typography>
          </div>
        </Grid>
        <Grid item md={7}>
          <div className={classes.imageContainer}>
            <img src={usImage}
                 alt="Cecilia Devitt-Barbecot and Thibault Drevon, Co-CEOs"/>
          </div>
        </Grid>
      </Grid>
      <Grid container justify='center' spacing={4} className={classes.values}>
        {VALUES.map(props => <Value {...props} key={props.title}
                                    classes={classes}/>)}
      </Grid>
    </div>
  </section>
);

export default withStyles(styles)(intro);