import React from 'react';
import {Link, Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../shared/images/logo-white.png";
import classNames from "classnames";
import Grid from "@material-ui/core/Grid";
import channels from "../../shared/channels";
import {Translate} from "react-localize-redux";
import useScrollTo from "../../shared/util/hooks/useScrollTo";

const styles = theme => ({
  root: {
    textAlign: "center",
    paddingBottom: "2rem",
    paddingTop: "2rem",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  toolbar: {
    margin: "auto",
    padding: theme.spacing(2),
    transition: "width 0.5s ease",
    [theme.breakpoints.up('xs')]: {width: '100%'},
    [theme.breakpoints.up('sm')]: {width: '95%'},
    [theme.breakpoints.up('md')]: {width: '90%'},
    [theme.breakpoints.up('lg')]: {width: '85%'},
  },
  logo: {
    width: "60px",
    marginRight: theme.spacing(1),
  },
  mentions: {
    textAlign: "left",
    [theme.breakpoints.down('xs')]: {textAlign: 'center'},
    [theme.breakpoints.up('sm')]: {maxWidth: "600px"},
  },
  socialIcons: {
    fontSize: "18pt",
    textAlign: "left",
    [theme.breakpoints.down('xs')]: {textAlign: "center"},
    color: theme.palette.common.white,
    "& a": {
      marginRight: theme.spacing(1),
    }
  },
});

const footer = withStyles(styles)(({classes}) => {
  const scrollTo = useScrollTo();

  return (
    <footer className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Grid container spacing={8} alignItems="center" justify="center">
          <Grid item lg={1} md={1} sm={2} xs={12}>
            <a href="http://yellowstones.io">
              <img src={logo} className={classNames(classes.logo)} alt="Logo"/>
            </a>
          </Grid>
          <Grid item lg={9} md={8} sm={6} xs={12}>
            <Typography variant="body2" color="inherit"
                        className={classes.mentions} gutterBottom>
              &copy; Yellowstones {new Date().getFullYear()}.<br/>
              <Translate id={"Footer.credits"} options={{renderInnerHtml: true}}/>
            </Typography>
            <Typography variant="body2" color="inherit"
                        className={classes.mentions} gutterBottom>
              <Translate id="Footer.contactDetails"/>
            </Typography>
            <Typography variant="body2" className={classes.socialIcons}>
              {channels.map(({className, name, ...props}) => (
                <Link {...props} color="inherit"
                      key={props.href} rel="noopener noreferrer">
                  <i className={className}/>
                </Link>
              ))}
            </Typography>
          </Grid>
          <Grid item lg={2} md={3} sm={4} xs={12}>
            <Button color="inherit" variant="outlined" onClick={() => scrollTo("#home")}>
              <Translate id="Footer.backToTop"/>
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </footer>
  );
});

export default footer;