import React from 'react';
import {Link, withStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import thibault from "../../shared/images/thibault.jpeg";
import cecilia from "../../shared/images/cecilia.jpeg";
import Avatar from "@material-ui/core/Avatar";
import {ceciliaChannels, thibaultChannels} from "../../shared/channels";
import {Translate} from "react-localize-redux";

const styles = theme => ({
  root: {
    padding: "2rem",
    paddingTop: "4rem",
  },
  container: {
    maxWidth: 1200,
    margin: "auto",
    textAlign: "center",
  },
  imageContainer: {
    display: "flex",
    overflow: "hidden",
    margin: theme.spacing(3),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    boxShadow: theme.shadows[24],
  },
  text: {
    paddingRight: theme.spacing(3),
  },
  sectionTitle: {
    maxWidth: 600,
    margin: "auto",
  },
  bold: {
    fontWeight: "bold",
  },
  members: {
    marginTop: theme.spacing(6),
  },
  member: {
    maxWidth: 350,
    margin: "auto",
    paddingBottom: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  memberIcon: {
    width: 200,
    height: 200,
    marginBottom: theme.spacing(3),
  },
  socialIcons: {
    fontSize: "18pt",
    textAlign: "left",
    [theme.breakpoints.down('xs')]: {textAlign: "center"},
    color: theme.palette.primary.main,
    "& a": {
      marginRight: theme.spacing(1),
      "& svg":{
      transition: "transform 0.1s ease",
      "&:hover": {
        transform: "scale(1.15)",
      }
      }
    }
  },
});


const Member = ({image, name, title, channels, classes}) => (
  <Grid item sm={6} md={4}>
    <article className={classes.member}>
      <Avatar color="primary" variant="circle" className={classes.memberIcon}
              src={image}/>
      <Typography variant="h6" className={classes.bold}>{name}</Typography>
      <Typography variant="body1" color="textSecondary" paragraph><Translate id={title}/></Typography>
      <Typography variant="body2" className={classes.socialIcons}>
        {channels.map(({className, name, ...props}) => (
          <Link {...props} color="inherit"
                key={props.href} rel="noopener noreferrer">
            <i className={className}/>
          </Link>
        ))}
      </Typography>
    </article>
  </Grid>
);


const MEMBERS = [
  {
    image: cecilia,
    title: "Team.ceciliaTitle",
    name: "Cecilia Devitt-Barbecot",
    channels: ceciliaChannels,
  },
  {
    image: thibault,
    title: "Team.thibaultTitle",
    name: "Thibault Drevon",
    channels: thibaultChannels,
  },
];

const team = ({classes}) => (
  <section className={classes.root} id="team">
    <div className={classes.container}>
      <div className={classes.sectionTitle}>
        <Typography variant="h4" paragraph className={classes.bold}>
          <Translate id="Team.title"/>
        </Typography>
        <Typography variant="body1" paragraph>
          <Translate id="Team.subtitle"/>
        </Typography>
      </div>
      <Grid container justify='center' spacing={4} className={classes.members}>
        {MEMBERS.map(props => <Member {...props} key={props.title}
                                      classes={classes}/>)}
      </Grid>
    </div>
  </section>
);

export default withStyles(styles)(team);