import React from 'react';
import AppBar from "../AppBar";
import Jumbo from "../Jumbo";
import Intro from "../Intro";
import Footer from "../Footer";
import {object} from "prop-types";
import {withStyles} from "@material-ui/core";
import Mission from "../Mission";
import Team from "../Team";
import Bobcaat from "../Bobcaat";

const styles = theme => ({
  root: {
    overflow: "hidden",
    width: "100%"
  },
});

const landingPage = (
{classes}
) => {

  return (
    <div className={classes.root}>
      <AppBar/>
      <Jumbo/>
      <Intro/>
      <Mission/>
      <Bobcaat/>
      <Team/>
      <Footer/>
    </div>
  );
};

landingPage.propTypes = {
  classes: object.isRequired,
};

export default withStyles(styles)(landingPage);
