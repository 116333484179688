import React, {useEffect} from 'react';
import {MuiThemeProvider} from "@material-ui/core";
import LandingPage from './components/LandingPage';
import CssBaseline from "@material-ui/core/CssBaseline";
import themeOptions from "./themeOptions";
import {renderToStaticMarkup} from "react-dom/server";
import globalTranslations from "./shared/localization/global.json";
import locales from "./shared/localization/locales";
import useUpdateLanguage from "./shared/util/hooks/useUpdateLanguage";
import {withLocalize} from "react-localize-redux";

const App = withLocalize(({initialize}) => {
  const updateLanguage = useUpdateLanguage();
  useEffect(() => {
    initialize({
      languages: locales.map(({name, slug}) => ({name, code: slug})),
      translation: globalTranslations,
      options: {
        renderToStaticMarkup,
        onMissingTranslation: ({translationId}) => translationId,
      }
    });
    updateLanguage();
  }, [initialize, updateLanguage]);



  return (
    <MuiThemeProvider theme={themeOptions.light}>
      <CssBaseline/>
      <LandingPage/>
    </MuiThemeProvider>
  );
});

export default App;
