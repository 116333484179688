import React from 'react';
import {render} from 'react-snapshot';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {LocalizeProvider} from "react-localize-redux";

const root = document.getElementById('root');

if (process.env.REACT_APP_STAGE === "prod") {
  document.head.insertAdjacentHTML('beforeend', '<script async src="https://www.googletagmanager.com/gtag/js?id=UA-113515284-1"/>');
  document.head.insertAdjacentHTML('beforeend', "<script>window.dataLayer = window.dataLayer || []; function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-113515284-1');</script>")
}

render(<LocalizeProvider><App/></LocalizeProvider>, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
